@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    max-width: 100vw;
    height: 100vh;
}

.intro {
    display: table;
    width: 100%;
    height: 100%;
    padding: 0;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

}

.intro .overlay {
    background: rgba(0, 0, 0, 0.253);
    height: 100%;
    backdrop-filter: blur(4.5px);
}


.intro h1 span {
    font-weight: 800;
    color: $primary-color;
}

.intro p {
    color: $on-secondary-background-color;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    text-transform: uppercase;

    @include respond-to("mobile") {
        font-size: 19px;
    }
}

.expandContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.introText {
    display: flex;
    text-align: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10vw 0 10vw;
}

.transparentContainer>h1 {
    margin-bottom: 30px;
}

.transparentContainer>p {
    margin-bottom: 30px;

    @include respond-to("mobile") {
        margin-bottom: 25px;
    }
}


.subtitle {
    text-transform: uppercase;
    color: $primary-color !important;
    font-weight: 600 !important;
}


.transparentContainer {
    background: rgba(0, 0, 0, 0.75);
    padding: 30px 40px 10px 40px;
    margin-top: 60px;
    max-width: 700px;


    @include respond-to("mobile") {
        margin-top: 40px;
        padding: 25px 15px 5px 15px;
        max-width: 99.9%;
        margin-bottom: 15px;
    }

}

.titleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    justify-content: center;
}

.megaText {
    color: $on-secondary-background-color;
    line-height: 1;
    font-size: 57px;
    text-transform: uppercase;
    margin-top: 7px;
    margin-bottom: 0;

    @include respond-to("mobile") {
        font-size: 45px;
        text-align: start;
    }
}

.btn {
    margin-bottom: 25px;
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 100px 0px;
    background: $secondary-background-color;
    color: $secondary-color;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.mainContainer h2 {
    color: $on-secondary-background-color;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1500px;
    margin: 0 80px;

    @include respond-to("mobile") {
        margin: 0 0;
        justify-content: center;
    }
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    margin: 0 100px;

    @include respond-to("laptop") {
        margin: 0 0;
    }
}

.logoImage {
    width: 220px;
    background-size: cover;
    object-fit: cover;
    padding: 25px;

    @include respond-to("laptop") {
        width: 180px;
        padding: 15px;
    }

    @include respond-to("mobile") {
        width: 135px;
        padding: 10px;
    }

}

.logoMainImage {
    width: 250px;
    background-size: cover;
    object-fit: cover;
    padding: 20px;

    @include respond-to("laptop") {
        width: 200px;
    }

    @include respond-to("mobile") {
        width: 150px;
        padding: 15px;
    }
}

.logoMainImage,
.logoImage {
    transition: all 0.3s ease-in-out;
}

.logoMainImage:hover,
.logoImage:hover {
    transform: scale(1.15);

}
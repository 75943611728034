@import "../../styles/styleguide.scss";

.mainContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1031;
    /* Ensure it appears above other content */
}

.loader {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
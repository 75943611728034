@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.textContent {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 1fr;
    gap: 10px;
    padding: 20px 10px;
    flex: 1;
    z-index: 1;


    @include respond-to("mobile") {
        margin: 0 57px;
        flex: 0.7;
        grid-template-columns: 1fr;
        /* Single column layout */
        grid-template-rows: auto;
    }
}

.gridItem {

    text-align: left;
}

.gridItem>h3 {
    font-size: 33px;
    letter-spacing: 1.4px;
}

.gridItem>div {
    font-size: 15px;
}
$primary-color: #eaa138;
$darker-primary: #966623;
$secondary-color: #fe097b;
$background-color: #f9f4f3;
$secondary-background-color: #252a28;
$transparent-secondary-background: #252a289d;
$on-background-color: #252a28;
$on-secondary-background-color: #ffffff;
$text-error-color: #cc0033;
$text-placeholder-color: #777;
$body-font-family: 'Barlow';
$heading-font-family: 'Gothic';
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.seasonImage {
    width: 100%;
    background-size: cover;
    object-fit: cover;
    border-radius: 20px;

}

.imageContainer {
    position: relative;
    text-align: center;
    color: white;
    flex: 1;
    transition: all 0.5s ease-in-out;
    border-radius: 20px;
    background-color: transparent;
}

.imageContainer img {
    width: 100%;
    height: auto;
    aspect-ratio: 17/12;

}

.textCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.textCenter p {
    font-size: 30px;
    font-weight: bold;
    width: 100%;

    @include respond-to("tablet") {
        font-size: 22px;
    }
}

.textCenter h2 {
    color: white;

    @include respond-to("tablet") {
        font-size: 42px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.smallP {
    font-size: 20px !important;
}
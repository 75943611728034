@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 100px 0;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: $primary-color;
    color: $on-secondary-background-color;
}

.mainContainer h2 {
    position: relative;
    color: $on-secondary-background-color;
}


.mainContainer p {
    line-height: 24px;
    margin-top: 20px;
    text-align: justify;
    padding-left: 30px;
    padding-right: 30px;
}

.video {
    margin-top: 80px;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;

    @include respond-to("tablet") {
        margin-top: 40px;
    }

}

.giganticLogo1 {
    position: absolute;
    top: -70px;
    right: -95px;

    @include respond-to("tablet") {
        display: none;
    }
}

.giganticLogo2 {
    position: absolute;
    bottom: -85px;
    left: 45%;
}

.giganticLogoMobile {
    position: absolute;
    top: -30px;
    right: -70px;
    display: none;

    @include respond-to("tablet") {
        display: block;
    }
}

.aboutText>strong {
    font-size: 20px;
}

.aboutText {
    font-size: 17px;
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding-bottom: 100px;
    padding-top: 80px;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.mainRow {
    display: flex;
    flex-direction: row;

    @include respond-to("tablet") {
        flex-direction: column;
    }
}

.expanded {
    flex: 1;
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.expanded p {
    margin-bottom: 50px;
    line-height: 3;
    color: black;
    font-size: 17px;

    @include respond-to("tablet") {
        font-size: 16px;
    }
}

.justifyLeft {
    text-align: start;
    margin-bottom: 40px;
    margin-top: 5px;
    font-size: 17px;
    color: $on-background-color;
}

.imageContainer:hover {
    cursor: pointer;
    box-shadow: -35px 35px 25px -20px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: -35px 35px 25px -20px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: -35px 35px 25px -20px rgba(0, 0, 0, 0.51);
}

.giganticLogo {
    position: absolute;
    bottom: -100px;
    right: -100px;
    transform: rotate(-60deg);
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.innerRow {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    flex: 1;
    padding-top: 30px;


}

.MhLogo {
    @include respond-to("mobile") {
        margin-right: 10px;
    }
}

.miniColumn {
    display: flex;

    @include respond-to("mobile") {
        flex-direction: column;
        align-items: start;
        justify-content: center;

    }

    justify-content: space-evenly;
    flex: 1;
    align-items: center;
    flex-direction: row;
    padding: 0;

}

.innerRow div {
    @include respond-to("mobile") {
        margin-top: 5px;
    }
}

.mainContainer {
    background: $primary-color;
    position: relative;
    z-index: 1;
}

.alternativeColor {
    background: $secondary-background-color;
}

.alternativeColorIcon {
    background: $secondary-color;
}

.alternativeColorA {
    background: $primary-color;
}

.mainContainer div {
    color: $on-secondary-background-color
}

.mainContainer a {
    color: $secondary-color;
}

.alternativeColor a {
    color: $primary-color;
}

.mainContainer a:hover {
    text-decoration: underline;
}

.logo {
    margin-right: 5px;
}


.direction {
    max-width: 100px;

    @include respond-to("mobile") {
        max-width: none;
        text-align: start;
        padding-top: 5px;
        width: 100%;
        padding-bottom: 15px;
    }
}

.secondRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    padding-bottom: 10px;
}
@import "./styleguide.scss";
@import "./breakpoints.scss";

@font-face {
  font-family: 'Gothic';
  src: url('./fonts/Gothic_ATF.otf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}



body {
  margin: 0;
  font-family: 'Barlow';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  scroll-behavior: smooth;
  background-color: $background-color;
}

h1 {
  font-family: $heading-font-family;
}

h2 {
  text-transform: uppercase;
  font-size: 56px;
  color: $on-background-color;
  font-family: $heading-font-family;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: $on-background-color;
  font-family: $heading-font-family;
}

h4 {
  font-size: 18px;
  color: $on-background-color;
  font-weight: 600;

}

h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

p {
  font-size: 15px;
}

a {
  color: $primary-color;
  font-weight: 400;
}

a:hover,
a:focus {
  text-decoration: none;
  color: $primary-color;
}

ul,
ol {
  list-style: none;
}

ul,
ol {
  padding: 0;
}

hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}


.section-title p {
  font-size: 18px;
}

.btn-custom {
  color: $on-secondary-background-color;
  background-color: $secondary-color;
  padding: 13px 65px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.2s linear;
  border: 2px solid $secondary-color;

  @include respond-to("mobile") {
    font-size: 13px;
  }
}

.btn-custom:hover {
  color: $secondary-color;
  background-color: #77777700;
}

.btn-custom:focus {
  color: $on-secondary-background-color;
  background-color: $secondary-color;
}


a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

.active>a,
.active>a:hover,
.active>a:focus {
  background-color: transparent !important;
}

.active>a:after,
.active>a:hover:after,
.active>a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, $darker-primary 0%, $darker-primary 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

/* Temporadas Section */
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: $secondary-color;
  background: linear-gradient(to right,
      $primary-color 0%,
      $secondary-color 100%);
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}

.hover-bg .hover-text>h4 {
  opacity: 0;
  color: $secondary-color;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}

.hover-bg:hover .hover-text>h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}


.hover-bg:hover .hover-text {
  opacity: 0.8;
}

.loadingImage {
  animation: fadeInBackground 0.2s ease-in-out infinite alternate;
  backdrop-filter: blur(10px);
}


@keyframes fadeInBackground {
  0% {
    background-color: white;
  }

  100% {
    background-color: $primary-color;
  }
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 100px 0;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: $primary-color;
    color: $on-secondary-background-color;
}

.mainContainer h2 {
    position: relative;
    color: $on-secondary-background-color;
}


.mainContainer p {
    line-height: 24px;
    text-align: justify;
}

.layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.giganticLogo1 {
    position: absolute;
    top: -70px;
    right: -95px;

    @include respond-to("tablet") {
        display: none;
    }
}

.giganticLogo2 {
    position: absolute;
    bottom: -85px;
    left: 35%;
}


// ACA ------------------------------------------------------
.timerContainer {
    background-color: $background-color;
    border-radius: 20px;
    position: relative;


    padding: 20px 60px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("tablet") {
        padding: 20px 20px;
    }


}

.spacer {
    margin-top: 15px;
}

.timerContainer h3 {
    text-align: center;
    font-size: 25px;
    letter-spacing: 2px;
    margin-top: 0;
    padding-top: 0;
    line-height: 1;

    @include respond-to("tablet") {
        font-size: 20px;
    }
}

.timerContainer span {
    color: $secondary-color;


}

.formContainer {
    color: $on-background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 900px;
    padding: 0 60px;

    margin: 20px 0;

    @include respond-to("tablet") {
        padding: 0px 40px;
    }

    @include respond-to("mobile") {
        min-height: 1600px;
        flex-direction: column;
        padding: 50px 0;
        max-width: 450px;
        margin: 20px 0;
    }

}

.formContainer strong {
    font-size: 20px;
    margin-bottom: 7px;
}

.svgBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

}

.innerFormContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    z-index: 1;
    background-color: white;
    border-radius: 15px;
    position: relative;

    height: 575px;
    padding: 20px 0px;

    @include respond-to("tablet") {
        height: 520px;
        padding: auto;
        padding: 20px 10px;

    }

    @include respond-to("mobile") {
        height: 450px;
        padding: 20px 0px;
        width: 100%;
    }




}


.innerFormContainer a {
    padding: 7.5px 60px;
    margin-top: 10px;
}

.innerFormContainer label {
    font-weight: bold;
    margin-bottom: 7px;
    width: 100%;
    padding-left: 33px;
}

.innerFormContainer input,
.innerFormContainer select {
    width: 300px;
    padding: 10px;
    border: none;
    background-color: #d1d6d4;
    color: black;
    font-size: 16px;

    &::placeholder {
        color: white;
    }
}

.innerFormContainer select {

    appearance: none;
    background-image:
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9IndoaXRlIiAvPgogIDxwb2x5Z29uIHBvaW50cz0iNTAsODUgMjAsMzAgODAsMzAiIGZpbGw9ImJsYWNrIiAvPgo8L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 25px;

}

.innerFormContainer>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textContent span {
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}

.textContent li {
    margin-bottom: 10px;
    font-size: 17.5px;
    margin-left: 5px;
}

.divider {
    width: 30px;
    height: 50px;

    @include respond-to("mobile") {
        height: 0px;
    }

}




.customFileInput {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #d3d7d4;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    position: relative;


    .customFileInputPlaceholder {

        color: white;
        font-size: 16px;
        flex: 1;
    }

    .fileInput {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 2;
        opacity: 0;
        cursor: pointer;

    }

    .fileIcon {
        background-color: white;

        width: 30px;
        height: 30px;
    }
}

.errorText {
    color: $text-error-color;
    font-size: 16px;
    margin-top: 5px;
}

.successText {
    color: green;
    font-size: 16px;
    margin-top: 5px;
}


// Spinner ------------------------------------------------------

.ldsDualRing,
.ldsDualRing:after {
    box-sizing: border-box;
}

.ldsDualRing {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: $secondary-color;
}

.ldsDualRing:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
    align-items: start;

}

.tncPopUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.742);
    z-index: 1000;
    animation: opacityAnimation 0.5s forwards;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.tncPopUpInner {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: $background-color;
    padding: 40px 60px;


    border-radius: 15px;
    width: 90%;
    max-width: 600px;
    animation: forwards 0.5s fromTheBottom;
    max-height: 570px;
    overflow-y: auto;

    @include respond-to("mobile") {
        padding: 20px 20px;
        max-height: 600px;
    }
}

@keyframes fromTheBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.tncPopUpInner>strong {
    font-size: 20px;
    margin-bottom: 15px;
}

.openModalButton {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}

.openModalButton:hover {
    text-decoration: none;
}

.innerFormContainer>div>label {
    margin-top: 20px;
}

.tncPopUpInner>ul,
.tncPopUpInner>ul>li>ul {
    padding-left: 20px;
    list-style-type: none;
}

.tncPopUpInner>ul>li,
.tncPopUpInner>ul>li>ul>li {
    margin-bottom: 15px;
}

li>span {
    margin-right: 10px;
}

.tncPopUpInner li {
    font-size: 16px;
}
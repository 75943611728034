@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 90px 0;
    max-width: 100vw;
    background: $background-color;
    overflow: hidden;
    min-height: 1000px;
    position: relative;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.mainContainer>h2::after {
    content: none !important;

}

.rowItem {
    width: 33%;
    min-width: 300px;
    padding: 10px;
    display: table;
    place-content: center;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
        .hoverEffect {
            filter: brightness(140%);
            box-shadow: 0px 0px 54px -15px rgba(0, 0, 0, 0.75);

        }
    }
}

.rowItem:hover {
    transform: scale(1.05);
}


.normalText {
    font-size: 20px;
    color: $secondary-background-color;
    text-align: start;
}

.giganticLogo {
    position: absolute;
    right: -90px;
    bottom: -90px;
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 90px 0;
    max-width: 100vw;
    background: $background-color;
    overflow: hidden;
    min-height: 1000px;
    position: relative;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include respond-to("mobile") {
        flex-direction: column;
        align-items: start;
        margin-bottom: 20px;

    }
}

.wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mainContainer>h2::after {
    content: none !important;

}


.normalText {
    font-size: 18px;
    color: $secondary-background-color;
    text-align: start;
}

.titleRow a {
    padding: 8px 35px;
}

.giganticLogo {
    position: absolute;
    top: 40%;
    left: -80px;
}

.giganticLogo2 {
    position: absolute;
    bottom: -80px;
    right: -80px;
    transform: rotate(-60deg);
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainNavContainer {
    transition: all 0.8s;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(231, 231, 231, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    max-width: 100vw;

}

.overlay {
    backdrop-filter: blur(4px);
    padding: 15px;


    @include respond-to("mobile") {
        padding: 10px;
        padding-left: 25px;
    }
}

.myNavHeader {
    height: 50px;
    padding: 0;
}

.logoText {
    font-size: 33px;
    margin-top: 6px;
    font-family: $heading-font-family;
    color: $on-secondary-background-color !important;

    @include respond-to("mobile") {
        font-size: 28px
    }
}

.mainHeaderTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mainNavContainer .navRow>li>a {
    text-transform: uppercase;
    color: $on-secondary-background-color;
    font-size: 17px;
    font-weight: 300;
    padding: 8px 2px;
    border-radius: 0;
    margin: 9px 20px 0;
}

.mainNavContainer .navRow>li>a:hover {
    color: $primary-color;
}

.mainNavContainer .navRow>li>a:active {
    color: $primary-color !important;
}

.mainNavContainer .navRow>li>a:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, $primary-color 0%, $primary-color 100%);
    content: "";
    transition: width 0.2s;
}

.mainNavContainer .navRow>li>a:hover:after {
    width: 100%;
}

.navbarToggle {
    border-radius: 0;
}

/*Buscar: .navbar-default .navbar-nav>.active>a:focus  si quiero cambiar el active color*/
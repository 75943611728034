@import "../../../styles/styleguide.scss";
@import "../../../styles/breakpoints.scss";

.mainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 900px;
    padding: 0 60px;

    margin: 20px 0;

    @include respond-to("tablet") {
        padding: 0 50px;
    }

    @include respond-to("mobile") {
        flex-direction: column;
        padding: 50px 0;
        max-width: 450px;
        margin: 20px 0;
    }

}

.svgBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

}

.textContent {
    flex: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    text-align: start;
    margin: 35px 0px;

    @include respond-to("mobile") {
        margin: 0;
    }
}

.columnStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    max-width: 320px;

    @include respond-to("tablet") {
        padding-left: 30px;
    }

    @include respond-to("mobile") {
        padding: 0 30px;
    }
}

.textContent h2 {
    color: white;
    font-size: 40px;
    padding: 0;
    margin: 0;
    line-height: 1;
}

.imageContainer {
    flex: 1;
    aspect-ratio: 1;
    z-index: 1;

    @include respond-to("mobile") {
        margin-bottom: 25px;
    }

}

.imageContainer img {
    width: 100%;

    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.61);


    @include respond-to("mobile") {
        max-width: 90vw;
    }

}

.hashtag {
    color: $primary-color;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
}

.hashtag span {
    color: white;
}

.author {
    color: $primary-color;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.summary {
    color: white;
    font-size: 17px;
    margin-bottom: 30px;
}

.columnStart a {
    padding: 6px 25px;
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding-bottom: 90px;
    max-width: 100vw;
    background: $background-color;
    overflow: hidden;
    min-height: 1000px;
    position: relative;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mainContainer>h2::after {
    content: none !important;

}

.normalText {
    font-size: 18px;
    color: $secondary-background-color;
    text-align: start;
    z-index: 1;
    position: relative;
}

.giganticLogo {
    position: absolute;
    top: 180px;

    @include respond-to("mobile") {
        top: 180px;
    }

    right: -90px;
}

.giganticLogo2 {
    position: absolute;
    bottom: -80px;
    left: -80px;
    transform: rotate(-60deg);
}

.banner {
    position: relative;
    height: 350px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: start;


    @include respond-to("mobile") {
        height: 300px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(3px);
}

.hashtag {
    color: $primary-color;
    font-weight: bold;
    font-size: 45px;
    margin-top: 110px;
    margin-left: 35px;

    @include respond-to("tablet") {
        margin-top: 150px;
    }

    @include respond-to("mobile") {
        margin-left: 0;
        margin-top: 90px;
    }

    width: 100%;
    z-index: 3;
    font-family: $heading-font-family;
    display: flex;
    flex-direction: row;
    justify-content: start;

}

.hashtag span {
    color: white;
}
@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    max-width: 100vw;
    background: $primary-color;
    overflow: hidden;
    position: relative;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.titleRow>h2 {
    color: $on-secondary-background-color;
}
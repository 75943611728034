@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {

    padding-top: 90px;
    padding-bottom: 50px;
    max-width: 100vw;
    background: $background-color;
    overflow: hidden;
    position: relative;
    min-height: 90vh;
    transition: all 1s linear;
}

.mainContainerOnPlay {
    background: rgb(32, 32, 32);
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mainContainer>h2::after {
    content: none !important;

}

.normalText {
    font-size: 18px;
    color: $secondary-background-color;
    text-align: start;
    z-index: 1;
    position: relative;
    transition: all 1s linear;
}

.giganticLogo {
    position: absolute;
    top: 300px;

    @include respond-to("mobile") {
        top: 180px;
    }

    right: -100px;
}

.giganticLogo2 {
    position: absolute;
    bottom: -80px;
    left: -80px;
    transform: rotate(-60deg);
}


.hashtag {
    color: $primary-color;
    font-weight: bold;
    font-size: 45px;
    width: 100%;
    z-index: 3;
    font-family: $heading-font-family;
    display: flex;
    flex-direction: row;
    justify-content: start;
    transition: all 1s linear;
}

.hashtag span {
    color: $on-background-color;
}

.bodyRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    @include respond-to("mobile") {
        flex-direction: column;
    }
}

.childVideo {
    flex: 1.7;
    background-color: black;
    position: relative;
    z-index: 2;
    border-radius: 20px;
}

.videoContainer {
    border-radius: 20px;
    position: relative;
    width: 100%;
    aspect-ratio: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.childVideoList {
    flex: 1;
    transition: all 1s linear;
    height: 440px;
    overflow-y: scroll;
    background-color: $on-secondary-background-color;
    position: relative;
    z-index: 2;
    padding: 10px 0;
}



.childVideoList::-webkit-scrollbar {
    background-color: #83838300;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
}



.separator {
    width: 45px;
    height: 20px;
}

.videoItemContainer,
.videoItemContainerSelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 13px 0px;
    padding: 5px 20px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.videoItemContainerSelected {
    background-color: #8f9291;

}

.videoImage {
    border-radius: 11px;
    width: 120px;
    aspect-ratio: 1.6;
    object-fit: cover;
}

.videoItemColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    margin-left: 20px;

}

.videoTitle {
    font-size: 16px;
    font-weight: bold;
    color: $on-background-color;
}

.videoSubTitle {
    font-size: 16.5px;
    color: $on-background-color;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.705);
    border-radius: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlay a {
    padding: 6px 25px;

    @include respond-to("mobile") {
        padding: 3px 18px;
    }
}

.nextVideoPoster {
    aspect-ratio: 1.8;
    height: 150px;
    object-fit: cover;
    margin: 15px 0;
    z-index: 2147483647;

    @include respond-to("mobile") {
        height: 80px;
        margin: 10px 0;
    }

}

.startCounterText,
.nextVideoTitle {

    color: $on-secondary-background-color;
    font-size: 18px;

    @include respond-to("mobile") {
        font-size: 15px;
    }
}

.nextVideoTitle {
    font-weight: bold;
    font-size: 26px;

    @include respond-to("mobile") {
        font-size: 20px;
    }
}

.startCounterText span {
    color: $primary-color;
    font-weight: 300;
}

.cancelButton {
    transition: all 0.2s linear;
    font-size: 15px;
}

.cancelButton:hover {
    color: $on-secondary-background-color;
}
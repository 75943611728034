@import "../../styles/styleguide.scss";
@import "../../styles/breakpoints.scss";

.mainContainer {
    padding: 90px 0;
    max-width: 100vw;
    background: $background-color;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.titleRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mainContainer>h2::after {
    content: none !important;

}

.normalText {
    font-size: 18px;
    color: $secondary-background-color;
    text-align: start;
    z-index: 1;
    position: relative;
}

.giganticLogo {
    position: absolute;
    top: 300px;

    @include respond-to("mobile") {
        top: 180px;
    }

    right: -100px;
}

.giganticLogo2 {
    position: absolute;
    bottom: -80px;
    left: -80px;
    transform: rotate(-60deg);
}


.hashtag {
    color: $primary-color;
    font-weight: bold;
    font-size: 45px;
    width: 100%;
    z-index: 3;
    font-family: $heading-font-family;
    display: flex;
    flex-direction: row;
    justify-content: start;

}

.hashtag span {
    color: $on-background-color;
}